<template>
  <div class="w-full flex flex-row items-center relative">
    <button
      @click="sideScroll('menu', 'left', 15, 100, 5)"
      class="absolute md:hidden top-1 -left-5 items-center justify-center cursor-pointer text-[#9E9E9E] bg-transparent hover:text-[#212121] py-2 px-2 w-8"
    >
      <svg
        class="w-4 h-4 rotate-180"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.3984 11.9999L6.47381 4.5916C5.88224 4.03808 5.83859 3.09563 6.37683 2.48726C6.91506 1.8789 7.83149 1.834 8.42305 2.38753L17.526 10.898C18.158 11.4897 18.158 12.5103 17.526 13.102L8.42305 21.6125C7.83149 22.166 6.91506 22.1211 6.37683 21.5127C5.8386 20.9044 5.88225 19.9619 6.47381 19.4084L14.3984 11.9999Z"
          fill="currentColor"
        />
      </svg>
    </button>
    <div
      v-dragscroll
      ref="menu"
      class="w-full flex flex-row items-center overflow-auto scroll-hide space-x-6 mx-3 md:mx-0"
    >
      <div
        ref="member"
        @click="clickMenu('member', 15)"
        class="font-solusi text-base font-semibold p-2 border-b-2 flex-shrink-0 duration-300 cursor-pointer"
        :class="
          currentRouteName === 'Mentor.Member'
            ? 'border-[#009DFF] text-[#009DFF]'
            : 'text-[#9E9E9E]   hover:text-[#383838] border-transparent'
        "
      >
        List Peserta
      </div>

      <div
        ref="task"
        @click="clickMenu('task', 15)"
        class="font-solusi text-base font-semibold p-2 border-b-2 flex-shrink-0 duration-300 cursor-pointer"
        :class="
          currentRouteName === 'Mentor.Task'
            ? 'border-[#009DFF] text-[#009DFF]'
            : 'text-[#9E9E9E]   hover:text-[#383838] border-transparent'
        "
      >
        List Tugas
      </div>

      <div
        ref="submission"
        @click="clickMenu('submission', 15)"
        class="font-solusi text-base font-semibold p-2 border-b-2 flex-shrink-0 duration-300 cursor-pointer"
        :class="
          currentRouteName === 'Mentor.Submission'
            ? 'border-[#009DFF] text-[#009DFF]'
            : 'text-[#9E9E9E]   hover:text-[#383838] border-transparent'
        "
      >
        Pengumpulan Tugas
      </div>

      <div
        ref="webinar-record"
        @click="clickMenu('webinar-record', 15)"
        class="font-solusi text-base font-semibold p-2 border-b-2 flex-shrink-0 duration-300 cursor-pointer"
        :class="
          currentRouteName === 'Mentor.WebinarRecord'
            ? 'border-[#009DFF] text-[#009DFF]'
            : 'text-[#9E9E9E]   hover:text-[#383838] border-transparent'
        "
      >
        Rekaman Webinar
      </div>

      <div
        ref="ask-mentor"
        @click="clickMenu('ask-mentor', 15)"
        class="font-solusi text-base font-semibold p-2 border-b-2 flex-shrink-0 duration-300 cursor-pointer"
        :class="
          currentRouteName === 'Mentor.AskMentor'
            ? 'border-[#009DFF] text-[#009DFF]'
            : 'text-[#9E9E9E]   hover:text-[#383838] border-transparent'
        "
      >
        Tanya Mentor
      </div>

      <div
        ref="request-webinar"
        @click="clickMenu('request-webinar', 15)"
        class="font-solusi text-base font-semibold p-2 border-b-2 flex-shrink-0 duration-300 cursor-pointer"
        :class="
          currentRouteName === 'Mentor.RequestWebinar'
            ? 'border-[#009DFF] text-[#009DFF]'
            : 'text-[#9E9E9E]   hover:text-[#383838] border-transparent'
        "
      >
        Request Webinar Plus
      </div>

      <div
        ref="private-webinar"
        v-show="privateWebinar"
        @click="clickMenu('private-webinar', 15)"
        class="font-solusi text-base font-semibold p-2 border-b-2 flex-shrink-0 duration-300 cursor-pointer"
        :class="
          currentRouteName === 'Mentor.PrivateWebinar'
            ? 'border-[#009DFF] text-[#009DFF]'
            : 'text-[#9E9E9E]   hover:text-[#383838] border-transparent'
        "
      >
        Konsultasi Desain
      </div>
    </div>
    <button
      @click="sideScroll('menu', 'right', 15, 100, 5)"
      class="absolute md:hidden top-1 -right-5 items-center justify-center cursor-pointer py-2 px-2 text-[#9E9E9E] bg-transparent hover:text-[#212121] w-8"
    >
      <svg
        class="w-4 h-4"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.3984 11.9999L6.47381 4.5916C5.88224 4.03808 5.83859 3.09563 6.37683 2.48726C6.91506 1.8789 7.83149 1.834 8.42305 2.38753L17.526 10.898C18.158 11.4897 18.158 12.5103 17.526 13.102L8.42305 21.6125C7.83149 22.166 6.91506 22.1211 6.37683 21.5127C5.8386 20.9044 5.88225 19.9619 6.47381 19.4084L14.3984 11.9999Z"
          fill="currentColor"
        />
      </svg>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { dragscrollNext } from 'vue-dragscroll'

export default defineComponent({
  name: 'Mentor Menus',
  directives: {
    dragscroll: dragscrollNext,
  },
  props: {
    code: String,
    privateWebinar: Boolean,
    loaded: Boolean,
  },
  computed: {
    currentRouteName(): any {
      return this.$route.name
    },
  },
  watch: {
    loaded(val: any) {
      if (val) {
        const splitFile = this.$route.path.split('/')
        const countArray = splitFile.length
        const name = splitFile[countArray - 1]
        this.clickMenu(name, 10)
      }
    },
  },
  methods: {
    clickMenu(el: string, speed: number) {
      this.$router.push('/mentor/classroom/' + this.code + '/' + el)
      const element: any = this.$refs['menu']
      const active: any = this.$refs[el]
      // const active: any = document.getElementById(el)
      const activeWidth = active.clientWidth / 2
      let pos = active.offsetLeft + activeWidth - 23
      const elpos = element.offsetLeft
      const elW = element.clientWidth
      pos = pos + elpos - elW / 2
      let scrollAmount = element.scrollLeft
      // console.log(scrollAmount)
      const slideMenu = setInterval(function() {
        if (scrollAmount >= pos) {
          element.scrollLeft -= 5
          scrollAmount -= 5
          if (scrollAmount < pos) {
            window.clearInterval(slideMenu)
          }
        } else {
          element.scrollLeft += 5
          scrollAmount += 5
          if (scrollAmount > pos) {
            window.clearInterval(slideMenu)
          }
        }
      }, speed)
    },
    sideScroll(
      el: string,
      direction: string,
      speed: number,
      distance: number,
      step: number
    ) {
      let scrollAmount = 0 as number
      const element: any = this.$refs[el]

      const slideTimer = setInterval(function() {
        if (direction == 'left') {
          element.scrollLeft -= step
        } else {
          element.scrollLeft += step
        }
        scrollAmount += step
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer)
        }
      }, speed)
      // console.log('width',element.scrollWidth)
      // console.log('left',element.scrollLeft)
    },
  },
})
</script>
