
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue'
import ClassroomModel from '@/models/ClassroomModel'

export default defineComponent({
  name: 'Detail Kelas',
  data() {
    return {
      classroomCode: this.$route.params.classroom_code as string,
      searchMember: '' as string,
      paginationMember: {
        perPage: 10 as number,
        lastPage: 2 as number,
        total: 0 as number,
        page: 1 as number,
        from: 0 as number,
        to: 0 as number,
      },
      quizLength: 0 as number,
      members: [] as any,
      isLoadingClassrooms: false,
      showDetailAchievement: false,
      selectedMember: {} as any,
    }
  },
  mounted() {
    this.getClassroomMember()
  },
  methods: {
    onPage(event: any) {
      this.paginationMember.page = event.page + 1
      this.paginationMember.perPage = event.rows
      this.getClassroomMember()
    },
    async getClassroomMember() {
      this.isLoadingClassrooms = true
      await ClassroomModel.getMentorClassroomMember(
        this.classroomCode,
        this.searchMember,
        this.paginationMember.perPage,
        this.paginationMember.page
      )
        .then(res => {
          this.isLoadingClassrooms = false
          if (res.success) {
            this.members = res.data
            this.quizLength = res.data[0].user_quiz.length
            this.paginationMember.total = res.pages.total
            this.paginationMember.from = res.pages.from
            this.paginationMember.to = res.pages.to
            this.paginationMember.lastPage = Number(res.pages.last_page)
          }
        })
        .catch(err => {
          this.isLoadingClassrooms = false
          if (
            err.response.data.errors[0].message ==
            'Anda bukan mentor di kelas ini'
          ) {
            this.$router.push('/mentor/classroom')
          }
          // console.log(err.response.data.errors[0].message)
        })
    },
    openDetailAchievement(data: any) {
      this.selectedMember = data
      this.showDetailAchievement = true
    },
    closeDetailAchievement() {
      this.showDetailAchievement = false
    },
  },
})
