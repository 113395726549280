
import { defineComponent } from 'vue'
import Menus from '@/main/views/app/_Layout/Mentor/Menus.vue'
import ClassroomModel from '@/models/ClassroomModel'
import { Classroom } from '@/dto'

export default defineComponent({
  name: 'ClassroomMainMentor',
  components: {
    Menus,
  },
  data() {
    return {
      classroomCode: this.$route.params.classroom_code as string,
      classroom: {} as Classroom,
      loaded: false,
    }
  },
  computed: {
    currentRouteName(): any {
      return this.$route.name
    },
  },
  mounted() {
    ClassroomModel.detailPrivateV2(this.classroomCode)
      .then(res => {
        this.loaded = true
        this.classroom = res.data
        if (this.currentRouteName == 'Mentor.PrivateWebinar') {
          if (!this.classroom?.is_webinar_private) {
            this.$router.push(
              '/mentor/classroom/' + this.classroomCode + '/member'
            )
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
  },
})
