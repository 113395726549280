
import { defineComponent } from 'vue'
import { dragscrollNext } from 'vue-dragscroll'

export default defineComponent({
  name: 'Mentor Menus',
  directives: {
    dragscroll: dragscrollNext,
  },
  props: {
    code: String,
    privateWebinar: Boolean,
    loaded: Boolean,
  },
  computed: {
    currentRouteName(): any {
      return this.$route.name
    },
  },
  watch: {
    loaded(val: any) {
      if (val) {
        const splitFile = this.$route.path.split('/')
        const countArray = splitFile.length
        const name = splitFile[countArray - 1]
        this.clickMenu(name, 10)
      }
    },
  },
  methods: {
    clickMenu(el: string, speed: number) {
      this.$router.push('/mentor/classroom/' + this.code + '/' + el)
      const element: any = this.$refs['menu']
      const active: any = this.$refs[el]
      // const active: any = document.getElementById(el)
      const activeWidth = active.clientWidth / 2
      let pos = active.offsetLeft + activeWidth - 23
      const elpos = element.offsetLeft
      const elW = element.clientWidth
      pos = pos + elpos - elW / 2
      let scrollAmount = element.scrollLeft
      // console.log(scrollAmount)
      const slideMenu = setInterval(function() {
        if (scrollAmount >= pos) {
          element.scrollLeft -= 5
          scrollAmount -= 5
          if (scrollAmount < pos) {
            window.clearInterval(slideMenu)
          }
        } else {
          element.scrollLeft += 5
          scrollAmount += 5
          if (scrollAmount > pos) {
            window.clearInterval(slideMenu)
          }
        }
      }, speed)
    },
    sideScroll(
      el: string,
      direction: string,
      speed: number,
      distance: number,
      step: number
    ) {
      let scrollAmount = 0 as number
      const element: any = this.$refs[el]

      const slideTimer = setInterval(function() {
        if (direction == 'left') {
          element.scrollLeft -= step
        } else {
          element.scrollLeft += step
        }
        scrollAmount += step
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer)
        }
      }, speed)
      // console.log('width',element.scrollWidth)
      // console.log('left',element.scrollLeft)
    },
  },
})
