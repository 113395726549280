<template>
  <div class="w-11/12 md:w-10/12 flex m-auto flex-col min-h-screen">
    <router-link
      to="/mentor/classroom"
      class="flex justify-start cursor-pointer items-center my-3"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        class="mr-2 w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.07942 12.3116C2.08342 12.3226 2.08342 12.3336 2.08842 12.3446C2.08842 12.3466 2.08842 12.3476 2.08942 12.3496C2.14942 12.4916 2.23742 12.6116 2.33942 12.7196C2.35042 12.7316 2.35442 12.7466 2.36542 12.7586L10.0134 20.3366C10.2624 20.5826 10.5884 20.7056 10.9144 20.7056C11.2404 20.7056 11.5654 20.5826 11.8144 20.3366C12.3124 19.8436 12.3124 19.0456 11.8144 18.5526L6.34042 13.1276L21.1054 13.1276C21.8094 13.1276 22.3784 12.5636 22.3784 11.8656C22.3784 11.1696 21.8094 10.6046 21.1054 10.6046L6.34142 10.6046L11.8154 5.15257C12.3134 4.66057 12.3134 3.86157 11.8154 3.36957C11.3184 2.87657 10.5114 2.87657 10.0134 3.36957L2.36542 10.9736C2.25542 11.0826 2.17642 11.2116 2.11442 11.3476C2.09442 11.3896 2.08342 11.4306 2.06942 11.4726C2.03542 11.5746 2.01442 11.6766 2.00742 11.7846C2.00442 11.8286 1.99842 11.8696 2.00042 11.9136C2.00542 12.0496 2.02942 12.1816 2.07942 12.3116Z"
          fill="currentColor"
        />
      </svg>
      <span class="font-desain font-semibold text-lg">Kembali</span>
    </router-link>
    <span class="font-desain font-semibold text-[#212121] text-2xl mb-3"
      >Kelas "{{ classroom?.name }} ({{ classroomCode }})"</span
    >
    <Menus
      :code="classroomCode"
      :privateWebinar="classroom?.is_webinar_private"
      :loaded="loaded"
    />
    <router-view v-slot="slotProps">
      <transition name="slide" mode="out-in">
        <component :is="slotProps.Component"></component>
      </transition>
    </router-view>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Menus from '@/main/views/app/_Layout/Mentor/Menus.vue'
import ClassroomModel from '@/models/ClassroomModel'
import { Classroom } from '@/dto'

export default defineComponent({
  name: 'ClassroomMainMentor',
  components: {
    Menus,
  },
  data() {
    return {
      classroomCode: this.$route.params.classroom_code as string,
      classroom: {} as Classroom,
      loaded: false,
    }
  },
  computed: {
    currentRouteName(): any {
      return this.$route.name
    },
  },
  mounted() {
    ClassroomModel.detailPrivateV2(this.classroomCode)
      .then(res => {
        this.loaded = true
        this.classroom = res.data
        if (this.currentRouteName == 'Mentor.PrivateWebinar') {
          if (!this.classroom?.is_webinar_private) {
            this.$router.push(
              '/mentor/classroom/' + this.classroomCode + '/member'
            )
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
  },
})
</script>
<style lang="css">
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateX(-1rem);
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}
.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateX(0);
}
</style>
