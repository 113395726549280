<template>
  <div class="w-full md:mx-2 rounded">
    <div class="mb-10 bg-white">
      <div
        class="flex justify-between border-b border-gray-300 mb-3 items-center px-2"
      >
        <p class="p-3 font-desain text-[#212121] font-semibold text-lg">
          List Peserta
        </p>
      </div>
      <div class="w-full m-auto mb-6">
        <DataTable
          :value="members"
          :first="paginationMember.from"
          :paginator="true"
          :rows="paginationMember.perPage"
          dataKey="id"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 25, 50]"
          class="p-datatable-sm font-solusi text-sm border border-gray-200"
          currentPageReportTemplate="Menampilkan {first} hingga {last} dari {totalRecords} member"
          :totalRecords="paginationMember.total"
          responsiveLayout="scroll"
          :lazy="true"
          :loading="isLoadingClassrooms"
          @page="onPage($event)"
          :rowHover="true"
        >
          <template #empty>
            <div
              class="text-center font-semibold text-[#DE1306] font-solusi text-base"
            >
              Belum ada member
            </div>
          </template>
          <template #loading>
            <div class="text-center font-semibold font-solusi text-base">
              Loading member data. Please wait.
            </div>
          </template>
          <Column
            field="name"
            header="No."
            headerClass="font-solusi font-semibold w-12"
            bodyClass="font-solusi font-semibold text-sm"
          >
            <template #body="{index}">
              {{ index + paginationMember.from }}.
            </template>
          </Column>
          <Column
            field="name"
            header="Nama"
            bodyClass="font-solusi font-medium text-sm"
          >
            <template #body="{data}">
              {{ data.name }}
            </template>
          </Column>

          <Column
            v-for="i in quizLength"
            :key="i"
            field="name"
            :header="'Quiz ' + i"
            bodyClass="font-solusi font-medium text-sm w-18"
          >
            <template #body="{data}">
              {{ data?.user_quiz[i - 1]?.score }}
            </template>
          </Column>

          <Column
            field="name"
            header="Status"
            headerClass="w-28"
            bodyClass="font-solusi font-medium text-sm"
          >
            <template #body="{data}">
              <span
                v-if="data.is_done"
                class="font-solusi text-xs font-semibold inline-block py-1 px-2 rounded text-green-600 bg-green-200 last:mr-0 mr-1"
              >
                Lulus
              </span>
              <span
                v-else
                class="font-solusi text-xs font-semibold inline-block py-1 px-2 rounded text-red-600 bg-red-200 last:mr-0 mr-1"
              >
                Belum Lulus
              </span>
            </template>
          </Column>

          <!-- <Column
            field="name"
            header="Aksi"
            headerClass="w-60"
            bodyClass="font-solusi font-medium text-sm"
          >
            <template #body="{data}">
              <button
                @click="openDetailAchievement(data)"
                class="font-solusi text-xs py-2 px-4 text-center rounded-full text-[#009DFF] border border-blue-400 hover:text-white hover:bg-blue-500 font-semibold duration-300"
              >
                Pencapaian Peserta
              </button>
            </template>
          </Column> -->
        </DataTable>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="showDetailAchievement"
        class="opacity-50 fixed inset-0 z-51 bg-black"
      ></div>
    </transition>
    <transition
      enter-active-class="animated bounceIn"
      leave-active-class="animated bounceOut"
    >
      <div
        v-if="showDetailAchievement"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex"
        style="z-index:51"
      >
        <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
          <!--content-->
          <div
            v-click-away="closeDetailAchievement"
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[80vh]"
          >
            <!--header-->
            <div class="flex items-start justify-between p-3 pb-0 rounded-t">
              <h3
                class="text-xl font-semibold text-[#212121] font-desain text-center"
              >
                Detail Pencapaian Peserta
              </h3>
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeDetailAchievement()"
              >
                <svg
                  class="fill-current text-[#383838]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  ></path>
                </svg>
              </div>
            </div>
            <!--body-->
            <div
              class="pt-3 pb-6 px-6 flex-auto overflow-x-hiden overflow-y-auto scroll-custom"
            >
              <div class="mb-3">
                <span class="font-solusi font-semibold text-[#333333]"
                  >Member:
                </span>
                <p class="font-solusi text-[#333333] ml-3">
                  {{ selectedMember?.name ? selectedMember?.name : '-' }}
                </p>
              </div>
              <div class="mb-3">
                <span class="font-solusi font-semibold text-[#333333]"
                  >Status:
                </span>
                <p class="font-solusi text-[#333333] ml-3">
                  {{ selectedMember?.is_done ? 'Lulus' : 'Belum Lulus' }}
                </p>
              </div>
              <div class="mb-3">
                <span class="font-solusi font-semibold text-[#333333]"
                  >Selesai Video:
                </span>
                <p class="font-solusi text-[#333333] ml-3">
                  {{
                    selectedMember?.is_subject_done
                      ? 'Selesai'
                      : 'Belum Selesai'
                  }}
                </p>
              </div>
              <div class="mb-3">
                <span class="font-solusi font-semibold text-[#333333]"
                  >Selesai Tugas:
                </span>
                <p class="font-solusi text-[#333333] ml-3">
                  {{
                    selectedMember?.is_task_done ? 'Selesai' : 'Belum Selesai'
                  }}
                </p>
              </div>
              <div
                v-for="(quiz, i) in selectedMember.user_quiz"
                :key="i"
                class="mb-3"
              >
                <span class="font-solusi font-semibold text-[#333333]"
                  >Quiz {{ i + 1 }}:
                </span>
                <p class="font-solusi text-[#333333] ml-3">
                  {{ quiz.score > 0 ? quiz.score : '-' }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue'
import ClassroomModel from '@/models/ClassroomModel'

export default defineComponent({
  name: 'Detail Kelas',
  data() {
    return {
      classroomCode: this.$route.params.classroom_code as string,
      searchMember: '' as string,
      paginationMember: {
        perPage: 10 as number,
        lastPage: 2 as number,
        total: 0 as number,
        page: 1 as number,
        from: 0 as number,
        to: 0 as number,
      },
      quizLength: 0 as number,
      members: [] as any,
      isLoadingClassrooms: false,
      showDetailAchievement: false,
      selectedMember: {} as any,
    }
  },
  mounted() {
    this.getClassroomMember()
  },
  methods: {
    onPage(event: any) {
      this.paginationMember.page = event.page + 1
      this.paginationMember.perPage = event.rows
      this.getClassroomMember()
    },
    async getClassroomMember() {
      this.isLoadingClassrooms = true
      await ClassroomModel.getMentorClassroomMember(
        this.classroomCode,
        this.searchMember,
        this.paginationMember.perPage,
        this.paginationMember.page
      )
        .then(res => {
          this.isLoadingClassrooms = false
          if (res.success) {
            this.members = res.data
            this.quizLength = res.data[0].user_quiz.length
            this.paginationMember.total = res.pages.total
            this.paginationMember.from = res.pages.from
            this.paginationMember.to = res.pages.to
            this.paginationMember.lastPage = Number(res.pages.last_page)
          }
        })
        .catch(err => {
          this.isLoadingClassrooms = false
          if (
            err.response.data.errors[0].message ==
            'Anda bukan mentor di kelas ini'
          ) {
            this.$router.push('/mentor/classroom')
          }
          // console.log(err.response.data.errors[0].message)
        })
    },
    openDetailAchievement(data: any) {
      this.selectedMember = data
      this.showDetailAchievement = true
    },
    closeDetailAchievement() {
      this.showDetailAchievement = false
    },
  },
})
</script>

<style scoped>
.tab {
  padding: 10px 20px;
}
.border-b-blue {
  border-bottom: 3px solid #009dff;
}
::v-deep(.p-column-title) {
  font-family: 'SolusiSans';
  font-weight: 700;
}
::v-deep(.p-paginator-current) {
  font-family: 'SolusiSans';
  font-weight: 600;
}
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page) {
  font-family: 'SolusiSans';
  font-weight: 500;
}
</style>
